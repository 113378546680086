<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div :class="['content-block', { 'contains-table': containsTable }]" v-html="modifiedHtmlCode" />
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: String,
    required: true,
  },
});

const modifiedHtmlCode = replaceImageSrc(props.content, '?$2&width=600&height=400&auto=webp');

const containsTable = computed<boolean>(() => {
  return props.content?.includes('<table');
});

function replaceImageSrc(htmlCode: string, queryParams: string) {
  const regex = /src="https:\/\/images\.contentstack\.io\/v3\/assets([^?"]+)\??([^"]*)"/g;
  return htmlCode?.replace(regex, `src="https://images.contentstack.io/v3/assets$1${queryParams}"`);
}
</script>

<style lang="scss" scoped>
.content-block {
  margin: 0 auto;
  @include local-mixins.tablet_and_mobile {
    max-width: calc(100% - local-vars.$mob-margin * 2);
    overflow: auto;
  }
  &.contains-table {
    max-width: unset;
    overflow: auto;
    margin: unset;
  }

  &:deep() table {
    margin: 0 auto;
    img {
      max-width: #{local-functions.rem-calc(240)};

      @include local-mixins.tablet {
        max-width: #{local-functions.rem-calc(150)};
      }
    }

    thead tr th,
    tbody tr td {
      padding: 0.5rem 1rem;
      text-align: center;
      &:first-child {
        border-left-style: none;
        font-weight: 500;
        @include local-mixins.desktop {
          min-width: #{local-functions.rem-calc(75)};
        }
      }
    }

    thead {
      tr {
        &:first-child {
          th {
            border-top-style: none;
          }
        }
        th {
          font-weight: 500;
          align-items: center;
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid $color-neutral-cool-900;
        }
        &:nth-child(2n + 1) {
          background-color: $color-neutral-cool-100;
        }
      }
    }
  }
}
</style>
