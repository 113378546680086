<template>
  <div
    ref="moduleElement"
    :class="['module', hlp_background?.animations]"
    :data-is-primary-asset-loaded="isPrimaryAssetLoaded"
    :data-is-module-in-view="isModuleInView"
    :style="{
      backgroundColor: hlp_background?.color,
    }"
  >
    <h2 v-if="seo_heading" class="hidden-heading">{{ seo_heading }}</h2>
    <component
      :is="hlp_background?.link_url ? linkComponent : 'div'"
      v-bind="hlp_background?.link_url ? { to: localePath(hlp_background?.link_url) } : {}"
      class="primary-asset-container"
    >
      <div v-if="hasImageBackground" class="picture-wrapper">
        <Picture :source-set="imageSources" />
      </div>

      <div style="position: relative">
        <div v-if="hasVideoBackground" class="video-wrapper">
          <HlpVideo
            :source-set="videoSrc"
            :autoplay="hlp_background?.video?.settings.autoplay"
            :preload="hlp_background?.video?.settings.preload"
            :lazy-load="hlp_background?.video?.settings.lazy_load"
            :pause-on-scroll-out="hlp_background?.video?.settings.pause_on_scroll_out"
            :reset-on-scroll-out="hlp_background?.video?.settings.reset_on_scroll_out"
            :options="videoOptions"
            @data-loaded="handleDataLoaded"
          />
        </div>
      </div>
    </component>

    <HlpModuleSubcomponents :components="components" />
    <div class="ticker-overlay">
      <div v-for="segment in scrollSegments" :key="segment" :class="'banner ' + bannerAnimClass + 'banner-' + segment">
        {{ preImageText }} <img :src="ticketImageUrl" class="banner-image" /> {{ postImageText }}
      </div>
    </div>
  </div>
  <div v-if="false"><!-- prevent props from being passed to the root element --></div>
</template>

<script setup lang="ts">
import { Breakpoint, breakpointOrder } from '~/configs/breakpoints';
import { AssetCloudinary } from '~/types/contentstack';
import { useDeviceStore } from '~/store/device';
import { useCloudinary } from '~/composables/cloudinary';

const deviceStore = useDeviceStore();
const localePath = useLocalePath();
const linkComponent = resolveComponent('NuxtLink');
const cloudinary = useCloudinary();

/* eslint-disable vue/prop-name-casing */
const props = defineProps<{
  seo_heading: string;
  hlp_background: {
    color: string;
    link_url: string;
    animations: string[];
    image: {
      source_set: {
        cloudinary_asset: AssetCloudinary[];
        breakpoint: Breakpoint;
        transform?: {
          width: number;
          height: number;
        };
        ticker_banner: {
          ticker_text: string;
          ticker_image_asset: AssetCloudinary[];
          ticker_image_location: number;
          ticker_animation_speed: number;
        };
      }[];
    };
    video: {
      source_set: {
        cloudinary_asset: AssetCloudinary[];
        transform?: {
          width: number;
          height: number;
        };
        poster: {
          cloudinary_asset: AssetCloudinary[];
          transform?: {
            width: number;
            height: number;
          };
        };
        breakpoint: Breakpoint;
      }[];
      settings: {
        autoplay?: boolean;
        loop?: boolean;
        muted?: boolean;
        plays_inline?: boolean;
        controls?: string; // @todo
        pause_on_scroll_out?: boolean;
        reset_on_scroll_out?: boolean;
        preload?: HTMLVideoElement['preload'];
        lazy_load?: boolean;
      };
    };
  };
  components: any;
}>();

const tBannerHolder =
  typeof props.hlp_background.image.source_set[0]?.ticker_banner !== 'undefined'
    ? props.hlp_background.image.source_set[0].ticker_banner
    : null;
const tickerBanner = {
  ticker_text: tBannerHolder ? tBannerHolder?.ticker_text : '',
  ticker_image_asset: tBannerHolder ? tBannerHolder?.ticker_image_asset : '',
  ticker_image_location: tBannerHolder ? tBannerHolder?.ticker_image_location : 0,
  ticker_animation_speed: tBannerHolder ? tBannerHolder?.ticker_animation_speed : 0,
};
const bannerScrollSpeed = tickerBanner.ticker_animation_speed + 's';
const bannerWidth = computed<string>(() => {
  const charWidth = 128;
  const wordWidth = charWidth * tickerBanner?.ticker_text.length + 300;
  return wordWidth + 'px';
});
function splitAfterXWords(toSplit: any, words: any) {
  // eslint-disable-next-line prettier/prettier
  const regex = new RegExp("(([\\s;:!,.?\"'’]*[^\\s]+){" + words + "})(.*)");
  const result = regex.exec(toSplit);
  return result ? [result[1], toSplit.substr(result[1].length)] : [toSplit, ''];
}
const preImageText = computed<string>(
  () => splitAfterXWords(tickerBanner?.ticker_text, tickerBanner?.ticker_image_location)[0] || ''
);
const postImageText = computed<string>(
  () => splitAfterXWords(tickerBanner?.ticker_text, tickerBanner?.ticker_image_location)[1] || ''
);
const transformOptions = { height: 115 };
const ticketImageUrl = computed<string>(
  () => cloudinary.generateImageUrl(tickerBanner?.ticker_image_asset[0]?.public_id, transformOptions) || ''
);

const bannerAnimClass = tickerBanner?.ticker_animation_speed === 0 ? '' : 'banner-animated ';
const scrollSegments = ['left', 'mid', 'right', 'last'];

function handleDataLoaded() {
  isPrimaryAssetLoaded.value = true;
}

const moduleElement = ref(null);
const isPrimaryAssetLoaded = ref(false);
const isModuleInView = ref(false);
// const screenWidth = ref(0);

const hasImageBackground = props.hlp_background?.image?.source_set.length !== 0;
// @note: videos are not shown if image backgrounds are configured
const hasVideoBackground = !hasImageBackground && props.hlp_background?.video?.source_set.length !== 0;

let intersectionObserver: IntersectionObserver | null = null;

const imageSources = computed(() => {
  if (!Array.isArray(props.hlp_background?.image?.source_set)) {
    return [];
  }
  const sources = [
    {
      cloudinary_asset: props.hlp_background.image.source_set[0].cloudinary_asset,
      transform: {
        width: 480,
        height: 714,
      },
      breakpoint: 'none',
    },
    {
      cloudinary_asset: props.hlp_background.image.source_set[0].cloudinary_asset,
      breakpoint: 'xxs',
      transform: {
        width: 768,
        height: 308,
      },
    },
    {
      cloudinary_asset: props.hlp_background.image.source_set[0].cloudinary_asset,
      breakpoint: 'sm',
      transform: {
        width: 1024,
        height: 411,
      },
    },
    {
      cloudinary_asset: props.hlp_background.image.source_set[0].cloudinary_asset,
      breakpoint: 'lg',
      transform: {
        width: 1440,
        height: 578,
      },
    },
    {
      cloudinary_asset: props.hlp_background.image.source_set[0].cloudinary_asset,
      breakpoint: 'xxl',
      transform: {
        width: 3840,
        height: 1541,
      },
    },
  ];
  if (props.hlp_background?.image?.source_set.length === 1) return sources;
  const sortedSources = [...props.hlp_background.image.source_set].sort((a, b) => {
    const aOrder = a.breakpoint === null ? breakpointOrder.none : breakpointOrder[a.breakpoint];
    const bOrder = b.breakpoint === null ? breakpointOrder.none : breakpointOrder[b.breakpoint];
    return aOrder - bOrder;
  });

  return sources.map((source, i) => {
    let foundBp = sortedSources.find((s) => s.breakpoint === source.breakpoint);
    //  || sortedSources[sortedSources.length - 1];
    if (!foundBp) {
      foundBp = i >= sortedSources.length - 1 ? sortedSources[sortedSources.length - 1] : sortedSources[0];
    }
    const asset = foundBp?.cloudinary_asset;
    return {
      cloudinary_asset: asset,
      transform: source.transform,
      breakpoint: source.breakpoint,
    };
  });
});

const videoOptions = computed<VideoOptions>(() => {
  return {
    width: deviceStore.isMobile ? 325 : deviceStore.isDesktop ? 1872 : 1024,
    height: deviceStore.isMobile ? 480 : deviceStore.isDesktop ? 749 : 410,
    quality: 'auto',
  };
});
// screenWidth.value = window.screen.width;
//   if (screenWidth.value > 1600) {
//     videoOptions.value.quality = 'auto';
//     videoOptions.value.width = screenWidth.value;
//     videoOptions.value.height = screenWidth.value * 0.4;
//   }

const videoSrc = computed(() => {
  const sources = props.hlp_background?.video?.source_set;
  const src =
    deviceStore.isDesktop || deviceStore.isTablet
      ? sources.find((s: { breakpoint: string }) => s.breakpoint === 'sm')
      : sources.find((s: { breakpoint: string }) => s.breakpoint !== 'sm');
  return [src ?? sources[0]];
});

onMounted(() => {
  // Initialize the IntersectionObserver
  intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      isModuleInView.value = entry.isIntersecting;
    });
  });

  // Start observing the element if it exists
  if (moduleElement.value) {
    intersectionObserver.observe(moduleElement.value);
  }
});

onUnmounted(() => {
  // Clean up the observer when the component is unmounted
  if (intersectionObserver) {
    intersectionObserver.disconnect();
    intersectionObserver = null;
  }
});
</script>

<style lang="scss" scoped>
.module {
  display: grid;
  grid-template-areas: 'stack';
  transition: opacity 1s, transform 1s;
}

.picture-wrapper {
  display: flex;
  :deep(picture) {
    width: 100%;
    margin: 0 auto;
  }
  :deep(img) {
    width: 100%;
    height: auto;
  }
}

.hidden-heading {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.primary-asset-container {
  grid-area: stack;
}

a.primary-asset-container video {
  pointer-events: none;
}

// Animations
.fx-fade-in-on-load {
  opacity: 0;

  &[data-is-primary-asset-loaded='true'] {
    opacity: 1;
  }
}

.fx-scale-down-on-load {
  transform: scale(1.3);

  &[data-is-primary-asset-loaded='true'] {
    transform: scale(1);
  }
}

.fx-scale-down-on-intersect {
  &[data-is-module-in-view='false'] {
    transform: scale(1.2);
  }
}

.fx-fade-in-on-intersect {
  opacity: 0.1;

  &[data-is-module-in-view='true'] {
    opacity: 1;
  }
}

.ticker-overlay {
  --bannerwidth-px: v-bind(bannerWidth);
  position: relative;
  // font-family: local-vars.$ff-hlp;
  // font-weight: bold;
  @include local-mixins.ff-hlp-93;
  font-size: 160px;
  color: #fff;
  top: -692px; // RETURN TO APPROX -692px
  right: -71px;
  pointer-events: none;
  @include local-mixins.mobile {
    font-size: 35px;
    top: -500px;
    right: -10px;
    --bannerwidth-px: calc(v-bind(bannerWidth) * (29 / 128));
  }
  width: 400%;
  display: inline-block;
}
.nonScrolling {
  text-align: center;
}
@keyframes moveBannerTextLeft {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(-1 * var(--bannerwidth-px)));
  }
}
.ticker-overlay :deep(.banner-image) {
  padding-left: 30px;
  @include local-mixins.mobile {
    padding-left: 20px;
    height: 25px;
  }
}
.ticker-overlay :deep(.banner) {
  position: absolute;
  height: 100%;
  min-width: fit-content;
  display: inline-table;
}
.ticker-overlay :deep(.banner-animated) {
  animation-name: moveBannerTextLeft;
  animation-duration: v-bind(bannerScrollSpeed);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.ticker-overlay :deep(.banner-mid) {
  left: calc((var(--bannerwidth-px)));
}
.ticker-overlay :deep(.banner-right) {
  left: calc((var(--bannerwidth-px)) * 2);
}
.ticker-overlay :deep(.banner-last) {
  left: calc((var(--bannerwidth-px)) * 3);
}
</style>
