<template>
  <div>
    <div :id="mmWrapperId" ref="mmWrapper" />
    <div :id="mmWrapper2Id" />
  </div>
</template>

<script setup lang="ts">
import { useCustomerStore } from '~/store/customer';
import { useLocaleStore } from '~/store/locale';
// import { generateId } from '~/util/generateId';

declare global {
  interface Window {
    MentionMe: any;
    MentionMeFiredTags?: {
      embedlandingpage: boolean;
      embeddashboard: boolean;
    };
  }
}

const customerStore = useCustomerStore();
const localeStore = useLocaleStore();
const $config = useRuntimeConfig();

type RenderType = 'dashboard' | 'landingpage' | 'cart';
type RenderTypeData = {
  firstname?: string;
  surname?: string;
  emailString: string;
  v2Follower: string;
  situation: string;
};

const props = defineProps({
  renderType: {
    type: String as () => RenderType,
    required: true,
  },
  reConfigVars: {
    type: Boolean,
    default: false,
  },
});

const hasLoadedWithEmail = ref(false);
const mmWrapperId = ref('mmWrapper');
const mmWrapper2Id = ref('mmWrapper2');
const mmWrapper = ref<HTMLElement | null>(null);
const observer = ref<MutationObserver | null>(null);
const situation = ref<string | null>(null);

if (props.reConfigVars) {
  const unwatchEmail = watch(
    () => customerStore.customer?.email,
    () => {
      if (hasLoadedWithEmail) unwatchEmail();
      hasLoadedWithEmail.value = true;
      loadMentionMeScript(true);
    }
  );
}

onMounted(() => {
  loadMentionMeScript();
});

onBeforeMount(() => {
  observer.value?.disconnect();
});

function loadMentionMeScript(loadSecondary = false) {
  const localeParts = (localeStore.langCode || 'en-us').split('-');
  const isoString = localeParts[0] + '_' + localeParts[1].toUpperCase();
  const scriptId = 'mmScript-script';
  const existing = document.getElementById(scriptId);
  const callback = () => {
    prepareScript(isoString, scriptId, existing);
  };
  if (loadSecondary && existing) {
    mutationSwapper(callback);
    return;
  }
  callback();
}

function mutationSwapper(callback: () => void) {
  if (situation.value === 'dashboard') return;
  observer.value = new MutationObserver((_mutations) => {
    const iframe = mmWrapper.value?.querySelector('iframe');
    if (iframe) {
      const tmpId = mmWrapperId.value;
      mmWrapperId.value = mmWrapper2Id.value;
      mmWrapper2Id.value = tmpId;
      if (mmWrapper.value) mmWrapper.value.innerHTML = '';
      callback();
      observer.value?.disconnect();
    }
  });
  if (mmWrapper.value) observer.value.observe(mmWrapper.value, { childList: true, subtree: true });
}

function resetMentionMe() {
  delete window.MentionMe;
  delete window.MentionMeFiredTags;
  const scripts = document.querySelectorAll('script');
  scripts?.forEach((script) => {
    if (script?.src?.includes('mention-me')) script.remove();
  });
}

function prepareScript(isoString: string | undefined, scriptId: string, existing: HTMLElement | null) {
  const mentionMeContainer = document.getElementById('mmWrapper');
  if (mentionMeContainer) mentionMeContainer.innerHTML = '';
  existing?.remove();
  resetMentionMe();
  const isDemo = $config.public?.environment !== 'production' ? '-demo' : '';
  const commonUrl = `https://tag${isDemo}.mention-me.com/api/v2`;

  const { firstname, surname, emailString, v2Follower, situation } = parseRenderType();

  const searchParams = new URLSearchParams({
    ...(!!firstname && { firstname: encodeURIComponent(firstname) }),
    ...(!!surname && { surname: encodeURIComponent(surname) }),
    situation,
    locale: isoString as string,
  }).toString();

  const mentionSrc = `${commonUrl}/${v2Follower}/mmbdcac62c${emailString}?${searchParams}`;

  const script = document.createElement('script');
  script.defer = true;
  script.id = scriptId;
  script.src = mentionSrc;
  document.body.appendChild(script);
}

function parseRenderType(): RenderTypeData {
  const email = customerStore.customer?.email;
  const varsType = !email && props.reConfigVars ? 'landingpage' : props.renderType;
  let firstname;
  let surname;
  let emailString = '';
  let v2Follower = 'referreroffer';
  situation.value = 'landingpage';

  switch (varsType) {
    case 'dashboard':
      firstname = customerStore.customer?.first_name;
      surname = customerStore.customer?.last_name;
      emailString = email ? `/${encodeURIComponent(email)}` : '';
      v2Follower = 'dashboard';
      situation.value = 'dashboard';
      break;
    case 'landingpage':
      v2Follower = 'referreroffer';
      situation.value = 'landingpage';
      break;
    case 'cart':
      v2Follower = 'refereefind';
      situation.value = 'cart';
      break;
    default:
      break;
  }

  // situation.value += '-' + generateId();

  return {
    firstname,
    surname,
    emailString,
    v2Follower,
    situation: situation.value,
  };
}
</script>

<style lang="scss" scoped>
#mmWrapper {
  :deep(a) {
    color: $color-primary-600;
    text-decoration: underline;

    &:hover {
      color: $color-primary-700;
    }
  }

  :deep(iframe) {
    margin: auto;
  }
}
</style>
