<template>
  <div :id="'dyc-' + campaign_id" :class="['dy-campaign', `dy-${findKeyFromInstance}`]">
    <ComponentSection
      v-if="variationComp"
      :id="'dyv-' + variationComp.variationId"
      :section-entry="variationComp.entry"
      :section-key="variationComp.key"
      :data-variation-name="variationComp.variationTitle"
      :data-dyv-ctl="variationComp.controlVariation ? '' : null"
      class="dy-variation"
    />
  </div>
</template>

<script setup lang="ts">
import { get } from 'lodash-es';
import { useDyStore } from '~/store/dy';
/* eslint-disable vue/prop-name-casing */

type variation_type = 'content_referenceConnection' | 'content_block' | 'grid' | 'accordion' | 'tabs';

export interface Variation {
  variation_id: string;
  control_variation: boolean;
  variation_title: string;
  variation_type: variation_type;
  content_referenceConnection: { [index: string]: any };
  grid: {
    content_referenceConnection: {
      edges: {
        node: { [index: string]: any };
      };
    };
  };
  tabs: {
    content: string;
    title: string;
  };
  content_block: string;
  accordion: {
    rich_text_editor: string;
    title: string;
  };
}

export interface Campaign {
  campaign_id: string;
  campaign_title: string;
  variations: Variation[];
}

interface IVariationAlt {
  entry: any;
  key: any;
  variationId: string;
  controlVariation: boolean;
  variationTitle: string;
}

const dyStore = useDyStore();

const props = defineProps({
  campaign_id: {
    type: String,
    required: true,
  },
  campaign_title: {
    type: String,
    required: true,
  },
  variations: {
    type: Array as () => Variation[],
    required: true,
  },
});

const variationComp = computed<IVariationAlt | undefined>(() => {
  let found = false;
  let targetDyCampaign;
  props.variations.forEach((variation) => {
    const dyCampaignInfo = dyStore.findByCampaignId({
      campaignId: props.campaign_id,
      variationId: variation.variation_id,
      skipVariation: false,
    });
    const isControl = variation?.control_variation;
    const variationType = variation.variation_type;
    let variationObj = variation[variationType];
    variationObj = get(variationObj, 'edges[0].node') || variationObj;
    // DY content blocks are single, expected array of obj with content prop for section blocks.
    // if (variationType === "content_block") variationObj = [{ content: variationObj }];
    // if (variationType === "content_block") variationObj = { content: variationObj };
    if (variationType === 'content_block') variationObj = { content: [variationObj] };
    const uid = get(variationObj, 'system.content_type_uid') || variationType;
    variationObj = (uid as variation_type | 'alert') === 'alert' ? variation[variationType] : variationObj;

    const compsMapper: any = {
      banner: 'banner',
      information_card: 'information_card',
      content_block: 'content_block',
      faq: 'faq',
      video: 'video',
      product_highlight: 'product_highlight',
      alternating_images: 'alternating_images',
      category_cards: 'category_cards',
      snackbar: 'snackbar',
      alert: 'topbanner',
      three_banner: 'three_banner',
      guided_selling_carousel: 'guided_selling_carousel',
      hlp_hero_module: 'hlp_hero_module',
      hlp_category_module: 'hlp_category_module',
      hlp_product_category_module: 'hlp_product_category_module',
      hlp_ticker_module: 'hlp_ticker_module',
      hlp_image_module: 'hlp_image_module',
      hlp_video_module: 'hlp_video_module',
    };

    if ((!found && isControl) || !dyCampaignInfo.noMatch) {
      targetDyCampaign = {
        entry: variationObj,
        key: compsMapper[uid],
        variationId: variation.variation_id,
        controlVariation: variation.control_variation,
        variationTitle: variation.variation_title,
      };
      if (!dyCampaignInfo.noMatch) found = true;
    }
  });
  return targetDyCampaign;
});
const findKeyFromInstance = computed<string>(() => {
  return variationComp.value?.key || '';
});
</script>
